import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Card, Button } from 'antd'
import { getPromotions } from '../../reducers'
import { APIpath, fetchPromotions } from '../../actions'
import Layout from '../../layout'
import styles from '../../styles'

const { Column } = Table

const tablePromotions = (promotions) => {
    return promotions.map((promotion) => {
        return {
            key: promotion.id,
            name: promotion.name,
            description: promotion.description,
            status: promotion.status,
            image: promotion.image,
            filetype: promotion.filetype
        }
    })
}

class PromotionList extends React.Component {

    componentDidMount() {
        this.props.fetchPromotions()
    }

    render() {
        const { promotions } = this.props
        return (
            <Layout type={"backpage"} >
                <Card style={styles.generalCard}>
                    <Table dataSource={tablePromotions(promotions)} >
                        <Column title={"Nombre"} render={(record) => record.name} />
                        <Column title={"Descripción"} render={(record) => record.description} />
                        <Column title={"Estado"} render={(record) => record.status ? 'Activo' : 'Inactivo'} />
                        <Column title={"Banner"} render={(record) =>
                            <img src={`${APIpath}/promotion/image/${record.key}`} style={{width: '60px', height: '60px'}} alt={record.key} />
                        } />
                        <Column title={"Opciones"} render={(record) =>
                            <Link to={`/admin/promotion/edit/${record.key}`}>
                                <Button type={"primary"}>Editar</Button>
                            </Link>
                        } />
                    </Table>
                </Card>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    promotions: getPromotions(state)
})

const mapDispatchToProps = { fetchPromotions }

export default connect(mapStateToProps, mapDispatchToProps)(PromotionList)