import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUser, getAuth } from '../../reducers'
import {
    Card, Row, Col, Typography
} from 'antd'
import CardHeaderInformation from '../../components/CardHeaderInformation'
import Layout from '../../layout'
import background2 from '../../multimedia/background2.svg'
import icon1 from '../../multimedia/icon1.svg'
import icon3 from '../../multimedia/icon3.svg'
import icon4 from '../../multimedia/icon4.svg'
import icon10 from '../../multimedia/icon10.svg'
import styles from '../../styles'

class Home extends React.Component {

    render() {
        const { auth } = this.props
        return <Layout type={"frontpage"}>
            <Card
                cover={<img src={background2} alt={"Fondo"} />}
                bordered={false}
                bodyStyle={styles.cardNoPadding}
                style={styles.generalCard}
            >
                <CardHeaderInformation 
                    name={auth.cNombres + ' ' + auth.cApePat + ' ' + auth.cApeMat}
                    dni={auth.cDNI}
                />
                
                <Card className={'text-center'}>
                    <Row>
                        <Col span={12}>
                            <Link to={"/account"}>
                                <img src={icon1} alt={"Mi cuenta maple"} style={styles.iconMenu} />
                                <Typography.Title level={5} > Mi cuenta MAPLE</Typography.Title>
                            </Link>
                        </Col>
                        <Col span={12}>
                            <Link to={"/pre-avaluo"}>
                                <img src={icon3} alt={"Pre-valúos"} style={styles.iconMenu} />
                                <Typography.Title level={5} >Pre-avalúos</Typography.Title>
                            </Link>
                        </Col>
                        <Col span={12}>
                            <Link to='/agency'>
                                <img src={icon4} alt={"Directorio de agencias"} style={styles.iconMenu} />
                                <Typography.Title level={5} >Directorio de agencias</Typography.Title>
                            </Link>
                        </Col>
                        <Col span={12}>
                            <Link to='/promotions'>
                                <img src={icon10} alt={"Promociones"} style={styles.iconMenu} />
                                <Typography.Title level={5} >Promociones</Typography.Title>
                            </Link>
                        </Col>
                    </Row>
                </Card>
            </Card>
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    auth: getUser(state, getAuth(state))
})

const mapDispatchToProps = undefined

export default connect(mapStateToProps, mapDispatchToProps)(Home)