const entities = (state = {
    family: [],
    product: [],
    brand: [],
    line: [],
    model: [],
    prenda: [],
    departamento: [],
    municipio: []
}, action) => {

    switch (action.type) {
        case 'FETCH_ENTITIES': {
            return { ...state, [action.name]: action.entity }
        }
        default: return state
    }
}

export const getEntity = (state, name) => state[name]

export default entities