import { message } from 'antd'
import axios from 'axios'
import {
    FETCH_INVOICES,
    FETCH_VOUCHER,
    APIpath
} from '../actions'

const setInvoices = (invoices) => ({
    type: FETCH_INVOICES,
    invoices
})

const setVoucher = (voucher) => ({
    type: FETCH_VOUCHER,
    voucher
})

export const fetchInvoices = (contract) => (dispatch) => {
    axios.get(`${APIpath}/user/${contract}/invoice/`)
        .then(function (response) {
            dispatch(setInvoices(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}


export const fetchVoucher = (nNroTrans, nProd, nCodAge) => dispatch => {
    axios.get(`${APIpath}/user/voucher/${nNroTrans}/${nProd}/${nCodAge}`)
        .then(function (response) {
            if(response.data === ""){
                message.error("No posee voucher")
                dispatch(setVoucher({}))
            }else{
                dispatch(setVoucher(response.data))
            }
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}