import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUser, getAuth } from '../../reducers'
import {
    Card, Row, Col, Button
} from 'antd'
import Layout from '../../layout'
import background2 from '../../multimedia/background2.svg'
import CardHeaderInformation from '../../components/CardHeaderInformation'
import styles from '../../styles'

class Account extends React.Component {

    render() {
        const { auth } = this.props
        return <Layout type={"frontpage"}>
            <Card
                cover={<img src={background2} alt={"Fondo"} />}
                style={styles.generalCard}
                bordered={false}
                bodyStyle={styles.cardNoPadding}
            >
                <CardHeaderInformation 
                    name={auth.cNombres + ' ' + auth.cApePat + ' ' + auth.cApeMat}
                    dni={auth.cDNI}
                />
                <Card className={'text-center'}>
                    <Row>
                        <Col span={24}>
                            <Link to={"/account/contracts"}>
                                <Button block type={"primary"} size={"large"} ><b>VER CONTRATOS</b></Button>
                            </Link>
                        </Col>
                        <br/><br/><br/>
                        <Col span={24}>
                            <Link to={"/account/cash"}>
                                <Button block type={"primary"} size={"large"} ><b>EFECTIVO DISPONIBLE</b></Button>
                            </Link>
                        </Col>
                        <br/><br/><br/>
                        <Col span={24}>
                            <Link to='/account/goodies'>
                            <Button block type={"primary"} size={"large"} ><b>PREMIOS POR RECOMENDADOS</b></Button>
                            </Link>
                        </Col>
                    </Row>
                </Card>
            </Card>
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    auth: getUser(state, getAuth(state))
})

const mapDispatchToProps = undefined

export default connect(mapStateToProps, mapDispatchToProps)(Account)