import React from 'react'
import { connect } from 'react-redux'
import { fetchEntityWithId } from '../actions'
import { getEntity } from '../reducers'
import {
    Card, Form, Button, Input, Select
} from 'antd'
import styles from '../styles'

const { Option } = Select

class NoLoginForm extends React.Component {

    handleDepartamento = (value) => {
        let id = this.props.departamentos.filter(departamento => departamento.Departamento === value)[0].CodDepartamento
        this.props.fetchEntityWithId('municipio', id)
    }

    render() {
        const { setInitialForm, departamentos, municipios } = this.props
        return <Card
            style={styles.generalCard}
            bordered={false}
            headStyle={styles.cover}
        >
            <Form
                name={"noLoginForm"}
                onFinish={setInitialForm}
                layout={"vertical"}
            >
                <Form.Item label={"Celular"} name={"phone"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input type={"tel"} pattern={"[0-9]{8}"} placeholder={"########"} />
                </Form.Item>
                <Form.Item label={"Email"} name={"email"} >
                    <Input type={"email"} />
                </Form.Item>
                <Form.Item label={"Nombre"} name={"name"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Departamento"} name={"departamento"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select onChange={this.handleDepartamento}>
                        {departamentos.map(departamento =>
                            <Option value={departamento.Departamento} key={departamento.CodDepartamento} > {departamento.Departamento}</Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item label={"Municipio"} name={"municipio"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select>
                        {municipios.map(municipio =>
                            <Option value={municipio.Municipio} key={municipio.CodMunicipio}> {municipio.Municipio}</Option>
                        )}
                    </Select>
                </Form.Item>
                <br />
                <Button type={"primary"} htmlType="submit">Continuar</Button>
            </Form>
        </Card>
    }
}

const mapStateToProps = (state) => ({
    departamentos: getEntity(state, 'departamento'),
    municipios: getEntity(state, 'municipio')
})

const mapDispatchToProps = { fetchEntityWithId }

export default connect(mapStateToProps, mapDispatchToProps)(NoLoginForm)

