import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Result, Button } from 'antd'
import background1 from '../multimedia/background1.svg'
import styles from '../styles'
import { CheckCircleFilled } from '@ant-design/icons'

class ConfirmationBlock extends React.Component {

    render() {

        const { message } = this.props
        return <Card
            style={styles.generalCard}
            bordered={false}
            cover={<img src={background1} alt={"Fondo"} />}
            bodyStyle={styles.cardNoPaddingTop}
        >
            <Result
                icon={<CheckCircleFilled /> }
                subTitle={
                    <div style={styles.ThemeCover}>{message}</div>}
                extra={[
                    <Link to={"/"} key={1}>
                        <Button > Terminar</Button>
                    </Link>
                ]}
                style={styles.cardNoPadding}
            />
        </Card>
    }
}

export default ConfirmationBlock