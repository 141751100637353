import React from 'react'
import { Result } from 'antd'



class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    render() {
        const { hasError } = this.state
        if (hasError) {
            return <Result
                status="500"
                title="500"
                subTitle="Hubo un error, contacta al proveedor"
            />
        }
        return this.props.children
    }
}

export default ErrorBoundary