import React from 'react'
import { Link } from 'react-router-dom'
import { Result, Button } from 'antd'
import styles from '../styles'

const Error404 = ({ location }) => {
    return <Result
        status="404"
        title="404"
        subTitle="Lo sentimos, La página que usted esta visitando no existe."
        extra={
            <Link to="/">
                <Button style={styles.blockButton}>Regresar al inicio</Button>
            </Link>
        }
    />

}


export default Error404