const admin= (state = [], action) => {

    switch(action.type){
        case 'FETCH_ADMIN': 
            state.push(action.admin)
            return state
        case 'FETCH_ADMINS': return action.admins 
        default: return state
    }
}

export const getAdmins = (state) => state
export const getAdmin = (state, id) => state.reduce((p, admin) => {
    if (admin.id === Number(id)) return admin
    return p
}, {})


export default admin