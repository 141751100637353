import React from 'react'
import { connect } from 'react-redux'
import { logout } from '../actions'
import {
    getConfig
} from '../reducers'
import { Layout } from 'antd'
import ChatButton from '../components/ChatButton'
import styles from '../styles'

const { Content } = Layout


class NoLoginLayout extends React.Component {

    render() {
        const { children, config} = this.props
        return (
            <Layout style={styles.layout}>
                <Content style={styles.app}>
                    {children}
                </Content>
                <ChatButton phone={config.phone}  />
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    config: getConfig(state)
})

const mapDispatchToProps = ({ logout })

export default connect(mapStateToProps, mapDispatchToProps)(NoLoginLayout)
