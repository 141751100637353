import React from 'react'
import { connect } from 'react-redux'
import { logout } from '../actions'
import {
    getAdmin,
    getAuth
} from '../reducers'
import Appbar from '../components/AdminAppbar'
import { Layout } from 'antd'
import styles from '../styles'
import Sidebar from '../containers/Siderbar'

const { Content } = Layout

class BackpageLayout extends React.Component {


    state = {
        collapsed: false,
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        })
    }

    render() {
        const { children, isAuth, user, logout } = this.props
        const { collapsed } = this.state
        return (
            <Layout style={styles.layoutAdmin}>
                <Appbar isAuth={isAuth} user={user} logout={logout} onSidebar={this.toggle} sidebarCollapsed={collapsed} />
                <Layout style={styles.layoutAdmin}>
                    <Sidebar trigger={null} collapsible collapsed={collapsed} admin={user} />
                    <Content style={styles.app}>
                        {children}
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuth: getAuth(state) === undefined ? false : true,
    user: getAdmin(state, getAuth(state))
})

const mapDispatchToProps = ({ logout })

export default connect(mapStateToProps, mapDispatchToProps)(BackpageLayout)
