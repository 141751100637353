import React from 'react'
import { Layout } from 'antd'
import styles from '../styles'


const {  Content } = Layout

class AuthpageLayout extends React.Component {

    render() {
        const { children } = this.props
        return (
            <Layout style={styles.layoutAuthpage}>
                <br/>
                <br/>
                <br/>
                <br/>
                <Content style={styles.appWithMargin} >
                    {children}
                </Content>
            </Layout>
        )
    }
}

export default AuthpageLayout
