import axios from 'axios'
import { message } from 'antd'
import {
    LOG_IN,
    LOG_OUT,
    setAdmin,
    setUsers,
    APIpath
} from '../actions'

const log_in = (uid, role) => ({
    type: LOG_IN,
    uid,
    role
});

const log_out = () => ({
    type: LOG_OUT
});

export const login = (values) => (dispatch) => {

    axios.post(`${APIpath}/user/auth`, values)
        .then(function (response) {
            if (response.data.status) {
                dispatch(log_in(response.data.user[0].nCodPers, 'user'))
                dispatch(setUsers(response.data.user))
                message.success('Bienvenido')
            }else {
                message.error('Ha ocurrido un error al iniciar sesión')
            }
        })
        .catch(function (err) {
            console.log(err)
            message.error('Ha ocurrido un error al iniciar sesión')
        })
}

export const adminLogin = (values) => (dispatch) => {

    axios.post(`${APIpath}/admin/auth`, values)
        .then(function (response) {
            if (response.data.status) {
                dispatch(log_in(response.data.admin.id, 'admin'))
                dispatch(setAdmin(response.data.admin))
                message.success('Inicio de sesión exitoso')
            }else {
                message.error('Ha ocurrido un error al iniciar sesión')
            }
        })
        .catch(function (err) {
            console.log(err)
            message.error('Ha ocurrido un error al iniciar sesión')
        })
}

export const logout = () => (dispatch) => {
    dispatch(log_out())
}