import React from 'react'
import { Button } from 'antd'
import { WhatsAppOutlined } from '@ant-design/icons'

const ChatButton = (props) => {
    const { phone } = props
    return (
        <Button
            target={"_blank"}
            href={`https://wa.me/${phone}`}
            rel="noreferrer" icon={<WhatsAppOutlined style={{fontSize: '44px' }}  />}
            shape={"circle"}
            style={{
                zIndex: 99999,
                left: 'auto',
                right: '15px',
                position: 'fixed',
                bottom: '15px',
                transition: 'bottom .2s',
                background: '#25d366',
                color: '#FFF',
                width: '64px',
                height: '64px',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex'
            }}
        />
    )
}

export default ChatButton