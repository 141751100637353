const promotion = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_PROMOTIONS': return action.promotions
        case 'FETCH_PROMOTION':
            state.push(action.promotion)
            return state
        default: return state
    }
}

export const getPromotions = (state) => state
export const getPromotion = (state, id) => state.reduce((p, promotion) => {
    if ( Number(promotion.id) === Number(id)) return promotion
    return p
}, {})

export default promotion