const voucher = (state = {}, action) => {

    switch (action.type) {
        case 'FETCH_VOUCHER': return action.voucher
        default: return state
    }
}

export const getVoucher = (state) => state

export default voucher