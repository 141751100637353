import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Layout, Menu, Divider } from 'antd'
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined, LogoutOutlined } from '@ant-design/icons'
import { ReactComponent as Logo } from '../multimedia/logo.svg'
import styles from '../styles'

const { Header } = Layout

const Appbar = (props) => {

  const history = useHistory()
  const { logout, onSidebar = null, sidebarCollapsed = false } = props
  return (
    <div>
      <Header style={styles.appbar} >
        <Logo style={styles.logo} onClick={() => { history.push('/admin') }} />
        <Menu mode="horizontal">
          {onSidebar !== null &&
            <Menu.Item key={"toggle"} icon={sidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} onClick={onSidebar} />
          }
          <Menu.Item key="profile" icon={<UserOutlined />}>
            <Link to={"/admin/profile"}>Mi Perfil </Link>
          </Menu.Item>
          <Menu.Item key="logout" onClick={logout} icon={<LogoutOutlined />}>Cerrar sesión</Menu.Item>
        </Menu>
      </Header>
      <Divider style={styles.yellowLine} />
    </div>
  )
}

export default Appbar