import React from 'react'
import { connect } from 'react-redux'
import { fetchVoucher } from '../../actions'
import { getInvoice, getVoucher } from '../../reducers'
import Invoice from '../../components/Invoice'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Document, Page, pdfjs } from 'react-pdf';
import { Result } from 'antd'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class InvoiceDetail extends React.Component {

    componentDidMount() {
        const { id, age, prod } = this.props.match.params
        this.props.fetchVoucher(id, prod, age)
    }

    render() {
        const { voucher, invoice } = this.props
        if (Object.keys(voucher).length === 0) {
            return <Result
                status="404"
                title="Sin Voucher"
                subTitle="Este archivo no tiene voucher"
            />
        }
        return (
            <PDFDownloadLink document={<Invoice voucher={voucher} invoice={invoice} />} >
                {({ url, loading }) => loading ? '' : (
                    <Document file={url}>
                        <Page pageIndex={0} />
                    </Document>
                )}
            </PDFDownloadLink>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    invoice: getInvoice(state, ownProps.match.params.id),
    voucher: getVoucher(state)
})

const mapDispatchToProps = { fetchVoucher }

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetail)