import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from "react-router-dom"
import { adminLogin } from '../../actions'
import { getAuth } from '../../reducers'
import { Form, Field } from 'react-final-form'
import { Button, Card } from 'antd'
import { AntdInput as Input } from 'antd-final-form'
import { required } from '../../utils/validationForms'
import Layout from '../../layout'
import styles from '../../styles'

class Login extends React.Component {

    render() {
        const { location, adminLogin, isAuth } = this.props
        if (isAuth) {
            let { from } = location.state || { from: { pathname: '/admin/' } }
            return <Redirect to={from} />
        }

        return <Layout>
            <Card style={styles.cardAuthpage}>
                <Form
                    onSubmit={adminLogin}
                    render={({ handleSubmit, submitting, pristine }) => (
                        <form onSubmit={handleSubmit}>
                            <Field label="Correo" name="email" component={Input} placeholder={"Correo"} type={"email"} validate={required} />
                            <Field label="Clave" name="password" component={Input} placeholder={"Contraseña"} type={"password"} validate={required} />
                            <br/>
                            <Button type="primary" htmlType={'submit'} disabled={submitting || pristine} block>Iniciar sesión</Button>
                        </form>
                    )}
                >
                </Form>
            </Card>
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    isAuth: getAuth(state) === undefined ? false : true
})

const mapDispatchToProps = ({ adminLogin })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))