import React from 'react'
import { Card, Typography, Divider } from 'antd'
import Dolar from '../multimedia/dolar.svg'
import styles from '../styles'

const { Meta } = Card

const CardHeaderInformation = (props) => {
    const { name, dni } = props
    return (
        <React.Fragment>
            <Meta
                avatar={
                    <img src={Dolar} style={{ width: '64x', height: '64px' }} alt={'maple'}/>
                }
                title={
                    <Typography.Text strong>{name}</Typography.Text>
                }
                description={<Typography.Text>{`DPI: ${dni}`}</Typography.Text>}
                style={styles.cardHeaderInformation}
            />
            <Divider style={styles.yellowLine} />
        </React.Fragment>
    )
}

export default CardHeaderInformation