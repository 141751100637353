import React from 'react'
import { connect } from 'react-redux'
import { saveJewelryPrevalueNo, fetchEntity } from '../../actions'
import Layout from '../../layout'
import Jewelry from '../../containers/Jewelry'
import AgencySelection from '../../containers/AgencySelection'
import EmailSend from '../../containers/EmailSend'
import ConfirmationBlock from '../../components/ConfirmationBlock'
import NoLoginForm from '../../containers/NoLoginForm'

class PrevalueJewelryNOL extends React.Component {


    componentDidMount() {
        this.props.fetchEntity('prenda')
    }

    state = {
        page: 1,
        metal: '',
        prenda: '',
        kilataje: '925',
        peso: 0,
        image1: [],
        image2: [],
        image3: [],
        image4: [],
        delivery: 2,
        agency: 0,
        address: '',
        addressRef: '',
        departamento: '',
        municipio: '',
        phone: '',
        email: '',
        name: '',
        contactFrom: 2
    }

    setJewelry = (entities) => {
        this.setState(entities, () => { this.setState({ page: 3 }) })
    }

    setInitialForm = (values) => {
        this.setState(values, () => { this.setState({ page: 7 }) })
    }

    setUpdateState = (object) => {
        this.setState(object)
    }


    setAgency = (value) => {
        this.setState({ agency: value }, () => {
            this.setState({ page: 5 })
        })
    }


    setEmail = (values) => {
        this.setState(values, () => {
            this.saveForm()
        })
    }


    saveForm = () => {
        const { saveJewelryPrevalueNo } = this.props

        const formData = new FormData()
        this.state.image1.forEach(file => {
            formData.append('image1', file.originFileObj)
        })
        this.state.image2.forEach(file => {
            formData.append('image2', file.originFileObj)
        })
        this.state.image3.forEach(file => {
            formData.append('image3', file.originFileObj)
        })
        if (typeof this.state.image4 !== 'undefined') {
            this.state.image4.forEach(file => {
                formData.append('image4', file.originFileObj)
            })
        }
        formData.append('metal', this.state.metal)
        formData.append('prenda', this.state.prenda)
        formData.append('kilataje', this.state.kilataje)
        formData.append('peso', this.state.peso)
        formData.append('delivery', this.state.delivery)
        formData.append('agency', this.state.agency)
        formData.append('address', this.state.address)
        formData.append('addressRef', this.state.addressRef)
        formData.append('departamento', this.state.departamento)
        formData.append('municipio', this.state.municipio)
        formData.append('contactFrom', this.state.contactFrom)
        formData.append('nCodPers', 0)
        formData.append('name', this.state.name)
        formData.append('phone', this.state.phone)
        formData.append('email', this.state.email)
        saveJewelryPrevalueNo(formData)
        this.setState({ page: 6 })
    }

    cancelInitialForm = () => this.setState({ page: 1, address: '', phone: '', addressRef: '', departamento: '', municipio: '' })
    cancelAgency = () => this.setState({ page: 1, agency: 0 })
    cancelEmail = () => this.setState({ page: 3 })
    


    render() {
        const { page } = this.state
        switch (page) {
            case 1:
                return <Layout type={"NoLogin"} >
                    <NoLoginForm cancelInitialForm={this.cancelInitialForm} setInitialForm={this.setInitialForm} />
                </Layout>
            case 3:
                return <Layout type={"NoLogin"} >
                    <AgencySelection cancelAgency={this.cancelAgency} setAgency={this.setAgency} />
                </Layout>
            case 5:
                return <Layout type={"NoLogin"} >
                    <EmailSend cancelEmail={this.cancelEmail} setEmail={this.setEmail} />
                </Layout>
            case 6:
                return <Layout type={"NoLogin"} >
                    <ConfirmationBlock
                        message={`Estimado cliente: Monto podrá incrementar de acuerdo al
                        valúo físico de su artículo.
                        Maple donde pagas menos y te damos más`}
                    />
                </Layout>
            default:
                return <Layout type={"NoLogin"} >
                    <Jewelry setJewelry={this.setJewelry} setUpdateState={this.setUpdateState} />
                </Layout>
        }
    }
}


const mapDispatchToProps = { saveJewelryPrevalueNo, fetchEntity }

export default connect(undefined, mapDispatchToProps)(PrevalueJewelryNOL)