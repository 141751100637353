const goodies = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_GOODIES': return action.goodies
        default: return state
    }
}

export const getGoodies = (state) => state

export default goodies