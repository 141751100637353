const ui = (state = {
    loader: false,
    redirect: false
}, action) => {

    switch (action.type) {
        case 'LOADING': return {...state, loader: action.status}
        case 'REDIRECT': return {...state, redirect: action.status }
        default: return state
    }
}

export const getLoader = (state) => state.loader
export const getRedirect = (state) => state.redirect

export default ui