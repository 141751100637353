import { message } from 'antd'
import axios from 'axios'
import {
    FETCH_PROMOTIONS,
    APIpath
} from '../actions'

const setPromotions = (promotions) => ({
    type: FETCH_PROMOTIONS,
    promotions
})

export const fetchPromotions = () => (dispatch) => {
    axios.get(`${APIpath}/promotion/`)
        .then(function (response) {
            dispatch(setPromotions(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}

export const fetchActivePromotions = () => (dispatch) => {
    axios.get(`${APIpath}/promotion/active-promotion`)
        .then(function (response) {
            dispatch(setPromotions(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}

export const createPromotion = (values) => (dispatch) => {
    axios.post(`${APIpath}/promotion/store`, values)
        .then(function (response) {
            message.success("Promoción guardada con exito")
            dispatch(setPromotions(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}

export const updatePromotion = (values) => (dispatch) => {
    axios.post(`${APIpath}/promotion/update`, values)
        .then(function (response) {
            message.success("Promoción actualizada con exito")
            dispatch(setPromotions(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}

export const updateStatusPromotion = (id) => dispatch => {
    axios.post(`${APIpath}/promotion/status`, { id })
        .then(function (response) {
            message.success("Promoción actualizada con exito")
            dispatch(setPromotions(response.data))
        })
        .catch(function (err) {
            message.error("Ha ocurrido un error")
            console.log(err)
        })
}