const user = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_USERS': return action.users
        case 'FETCH_USER':
            let userIn = state.filter( user => action.user.nCodPers === user.nCodPers )
            if(userIn.length > 0) {
                return state.map( user => {
                    if(action.user.nCodPers === user.nCodPers){
                        return action.user
                    }
                    return user
                })
            }else{
                state.push(action.user)
                return state
            }
        default: return state
    }
}

export const getUsers = (state) => state
export const getUser = (state, id) => state.reduce((p, user) => {
    if (user.nCodPers === Number(id)) return user
    return p
}, {})


export default user