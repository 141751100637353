import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUser, getAuth, getInvoices, getContract } from '../../reducers'
import { fetchInvoices } from '../../actions'
import {
    Card, Typography, Table, Button, List
} from 'antd'
import CardHeaderInformation from '../../components/CardHeaderInformation'
import Layout from '../../layout'
import styles from '../../styles'

const { Column } = Table

const tableinvoices = (invoices) => {
    return invoices.map(invoice => {
        return {
            key: invoice.NroDocumento,
            nNroTrans: invoice.nNroTrans,
            agency: invoice.cnomage,
            fOper: invoice.FOper
        }
    })
}


class InvoiceList extends React.Component {

    componentDidMount() {
        this.props.fetchInvoices(this.props.match.params.id)
    }


    render() {
        const { auth, invoices, contract } = this.props
        return (
            <Layout type={"frontpage"} >
                <Card bordered={false}
                    title={<Typography.Title level={2} className={"text-center"} style={styles.whiteText}>MIS FACTURAS</Typography.Title>}
                    headStyle={styles.cover}
                    bodyStyle={styles.cardNoPadding}
                    style={styles.generalCard}
                >
                    <CardHeaderInformation
                        name={auth.cNombres + ' ' + auth.cApePat + ' ' + auth.cApeMat}
                        dni={auth.cDNI}
                    />
                    <List
                        size="large"
                        dataSource={[
                            `N de contrato: ${contract.numerocontrato}`,
                            `Tipo de prestamo: ${contract.TipoProduc}`,
                            `Estado: ${contract.estado}`,
                            `Agencia: ${contract.agencia}`,
                            `Fecha de realización: ${ new Date(contract.fecha).toLocaleDateString('es')}`,
                            `Fecha de vencimiento: ${ new Date(contract.dfecvencimiento).toLocaleDateString('es')}`,
                            `Prestamo: Q${contract.pres}`,
                            `Saldo: Q${contract.sal}`,
                            `Renovación del mes: ${contract.TotalPagar}`
                        ]}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                    <br />
                    <Table dataSource={tableinvoices(invoices)} >
                        <Column title={"Documento"} render={(record) => record.key} />
                        <Column title={"Fecha Factura"} render={(record) => record.fOper} />
                        <Column title={"Detalles"} render={(record) =>
                            <Link to={`/account/invoice/check/${record.nNroTrans}/0/${contract.nProd}`}>
                                <Button type={"primary"}>Ver</Button>
                            </Link>
                        } />
                    </Table>
                </Card>
            </Layout>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    auth: getUser(state, getAuth(state)),
    invoices: getInvoices(state),
    contract: getContract(state, ownProps.match.params.id)
})

const mapDispatchToProps = { fetchInvoices }

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList)