import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Button } from 'antd'
import Layout from '../../layout'

class Dashboard extends React.Component {

    render() {
        return (
            <Layout type={"backpage"}>
                <Card>
                    <Link to={"/admin/user/list"}>
                        <Button type={"ghost"} block>Usuarios</Button>
                    </Link>
                    <Link to={"/admin/agency/list"}>
                        <Button type={"ghost"} block>Agencias</Button>
                    </Link>
                </Card>
            </Layout>
        )
    }
}

export default Dashboard