import React from 'react'
import { connect } from 'react-redux'
import { getAgencies } from '../reducers'
import {
    Card, Button, Radio, List, Avatar, Affix
} from 'antd'
import styles from '../styles'
import { APIpath } from '../actions'

class AgencySelection extends React.Component {


    handleValue = (e) => {
        this.setState({ agency: e.target.value })
    }

    handleAgency = () => {
        this.props.setAgency(this.state.agency)
    }

    render() {
        const { agencies, cancelAgency } = this.props
        return <Card
            style={styles.generalCard}
            bordered={false}
        >
            <Affix>
                <div style={styles.backgroundOpacity}>
                    <Button type={"ghost"} onClick={cancelAgency} >Regresar</Button>
                    <Button type={"primary"} onClick={this.handleAgency}>Continuar</Button>
                </div>
            </Affix>

            <Radio.Group onChange={this.handleValue}>
                <List>
                    {agencies.map(agency => {
                        return <List.Item
                            key={agency.id}
                            actions={[<Avatar size={64} src={`${APIpath}/agency/image/${agency.id}`} />]}
                        >
                            <Radio value={agency.name} >{agency.name}</Radio>
                        </List.Item>
                    })}
                </List>
            </Radio.Group>
        </Card>
    }
}

const mapStateToProps = (state) => ({
    agencies: getAgencies(state)
})

const mapDispatchToProps = undefined

export default connect(mapStateToProps, mapDispatchToProps)(AgencySelection)