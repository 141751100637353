import React from 'react'
import { connect } from 'react-redux'
import { logout } from '../actions'
import {
    getUser,
    getAuth,
    getConfig
} from '../reducers'
import Appbar from '../components/Appbar'
import { Layout } from 'antd'
import ChatButton from '../components/ChatButton'
import styles from '../styles'

const { Content } = Layout


class FrontpageLayout extends React.Component {

    render() {
        const { children, isAuth, user, config, logout } = this.props
        return (
            <Layout style={styles.layout}>
                <Appbar isAuth={isAuth} user={user} logout={logout} />
                <Content style={styles.app}>
                    {children}
                </Content>
                <ChatButton phone={config.phone}  />
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    isAuth: getAuth(state) === undefined ? false : true,
    user: getUser(state, getAuth(state)),
    config: getConfig(state)
})

const mapDispatchToProps = ({ logout })

export default connect(mapStateToProps, mapDispatchToProps)(FrontpageLayout)
