import React from 'react'
import { connect } from 'react-redux'
import { Form, Field } from 'react-final-form'
import { AntdInput as Input } from 'antd-final-form'
import { updateConfiguration } from '../../actions'
import { getConfig } from '../../reducers'
import { Card, Button } from 'antd'
import { required } from '../../utils/validationForms'
import Layout from '../../layout'
import styles from '../../styles'

class Configuration extends React.Component {


    render() {
        const { config, updateConfiguration } = this.props
        return <Layout type={"backpage"}>
            <Card
                style={styles.generalCard}
                title={"Configuración de aplicación"}
            >
                <Form
                    onSubmit={updateConfiguration}
                    initialValues={config}
                    render={({ handleSubmit, submitting, pristine }) => (
                        <form onSubmit={handleSubmit}>
                            <Field
                                label="Enlace de tienda"
                                name="store" 
                                component={Input}
                                placeholder={"Enlace de tienda"}
                                validate={required}
                            />
                            <br />
                            <Field
                                label="Teléfono principal"
                                name="phone"
                                component={Input}
                                placeholder={"Teléfono"}
                                validate={required}
                            />
                            <br />
                            <Button type="primary" htmlType={'submit'} disabled={submitting || pristine} block>Guardar</Button>
                        </form>
                    )}
                />
            </Card>
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    config: getConfig(state)
})

const mapDispatchToProps = ({ updateConfiguration })

export default connect(mapStateToProps, mapDispatchToProps)(Configuration)