import axios from 'axios'
import {
    FETCH_AGENCIES,
    APIpath
} from '../actions'

const setAgencies = (agencies) => ({
    type: FETCH_AGENCIES,
    agencies
})

export const fetchAgencies = () => (dispatch) => {

    axios.get(`${APIpath}/agency`)
        .then(function (response) {
            dispatch(setAgencies(response.data))
        })
        .catch(function (err) {
            console.log(err)
        })
}