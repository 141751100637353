import React from 'react'
import { connect } from 'react-redux'
import { getUser, getAuth } from '../../reducers'
import { changeUserPassword } from '../../actions'
import {
    Card, Form, Input, Button
} from 'antd'
import Layout from '../../layout'
import background2 from '../../multimedia/background2.svg'
import CardHeaderInformation from '../../components/CardHeaderInformation'
import styles from '../../styles'

class Profile extends React.Component {

    onSubmit = (values) => {
        const { auth, changeUserPassword } = this.props
        changeUserPassword({ ...values, nCodPers: auth.nCodPers })
    }

    render() {
        const { auth } = this.props
        return <Layout type={"frontpage"}>
            <Card
                cover={<img src={background2} alt={"Fondo"} />}
                bordered={false}
                style={styles.generalCard}
            >
                <CardHeaderInformation
                    name={auth.cNombres + ' ' + auth.cApePat + ' ' + auth.cApeMat}
                    dni={auth.cDNI}
                />
                <br />
                <Form
                    name={"changepassword"}
                    onFinish={this.onSubmit}
                    layout={"vertical"}
                >
                    <Form.Item name="old_password" label="Contraseña actual" required hasFeedback>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item name="password" label="Contraseña" rules={[{ required: true, message: 'Coloca tu nueva contraseña para actualizar', },]} hasFeedback>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item name="confirm" label="Repetir contraseña" dependencies={['password']} hasFeedback
                        rules={[{ required: true, message: 'Repite tu contraseña para continuar', },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Debo coincidir con la nueva contraseña'));
                            },
                        }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                        <Button type="primary" htmlType="submit">Actualizar</Button>
                    </Form.Item>
                </Form>
            </Card>
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    auth: getUser(state, getAuth(state))
})

const mapDispatchToProps = { changeUserPassword }

export default connect(mapStateToProps, mapDispatchToProps)(Profile)