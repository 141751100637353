

const agencies = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_AGENCIES': return action.agencies
        default: return state
    }
}

export const getAgencies = (state) => state
export const getAgency = (state, id) => state.reduce((p, agency) => {
    if (agency.id === Number(id)) return agency
    return p
}, {})

export default agencies