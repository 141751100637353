import axios from 'axios'
import { message } from 'antd'
import {
    FETCH_ADMINS,
    FETCH_ADMIN,
    APIpath
} from '../actions'

const setAdmins = (admins) => ({
    type: FETCH_ADMINS,
    admins
})

const setAdmin = (admin) => ({
    type: FETCH_ADMIN,
    admin
})

export const fetchAdmins = () => (dispatch) => {
    axios.get(`${APIpath}/admin`)
        .then(function (response) {
            dispatch(setAdmins(response.data))
        })
        .catch(function (err) {
            console.log(err)
        })
}

export const saveAdmin = (values) => dispatch => {
    axios.post(`${APIpath}/admin/store`, values)
        .then(function (response) {
            message.success('Usuario creado')
        })
        .catch(function (err) {
            console.log(err)
            message.error('Ha ocurrido un error')
        })
}

export const updateAdmin = (values) => dispatch => {
    axios.post(`${APIpath}/admin/update/`, values)
        .then(function (response) {
            message.success('Administrador actualizado')
        })
        .catch(function (err) {
            console.log(err)
            message.error('Ha ocurrido un error')
        })
}

export const updateAdminPassword = (values) => dispatch => {
    axios.post(`${APIpath}/admin/password/`, values)
        .then(function (response) {
            message.success('Administrador actualizado')
        })
        .catch(function (err) {
            console.log(err)
            message.error('Ha ocurrido un error')
        })
}

export {
    setAdmin
}