import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Layout, Menu, Divider } from 'antd'
import { UserOutlined, MenuOutlined, WalletOutlined, LogoutOutlined } from '@ant-design/icons'
import { ReactComponent as Logo } from '../multimedia/logo.svg'
import styles from '../styles'

const { Header } = Layout
const { SubMenu } = Menu

const Appbar = (props) => {

  const history = useHistory()
  const { isAuth, logout } = props
  return (
    <div>
      <Header style={styles.appbar} >
        <Logo style={styles.logo} onClick={() => { history.push('/') }} />
        <Menu mode="horizontal">
          {isAuth &&
            <SubMenu key="SubMenu" icon={<MenuOutlined />}>
              <Menu.Item key="profile" icon={<UserOutlined />} >
                <Link to={"/profile"}>Mi Perfil </Link>
              </Menu.Item>
              <Menu.Item key={"account"} icon={<WalletOutlined />}>
                <Link to={"/account"} >Mi cuenta MAPLE </Link>
              </Menu.Item>
              <Menu.Item key="logout" onClick={logout} icon={<LogoutOutlined />}>Cerrar sesión</Menu.Item>
            </SubMenu>
          }
        </Menu>
      </Header>
      <Divider style={styles.yellowLine} />
    </div>
  )

}

export default Appbar