import { message } from 'antd'
import axios from 'axios'
import {
    FETCH_CONTRACTS,
    APIpath
} from '../actions'

const setContracts = (contracts) => ({
    type: FETCH_CONTRACTS,
    contracts
})

export const fetchContracts = (dni) => (dispatch) => {
    axios.get(`${APIpath}/user/${dni}/contracts/`)
        .then(function (response) {
            dispatch(setContracts(response.data))
        })
        .catch(function (err) {
            console.log(err)
            message.error("Ha ocurrido un error")
        })
}
