const prevalues = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_PREVALUES': return action.prevalues
        case 'FETCH_PREVALUE':
            let prevalueIn = state.filter(prevalue => action.prevalue.id === prevalue.id)
            if (prevalueIn.length > 0) {
                return state.map(prevalue => {
                    if (action.prevalue.id === prevalue.id) {
                        return action.prevalue
                    }
                    return prevalue
                })
            } else {
                state.push(action.prevalue)
                return state
            }
        default: return state
    }
}

export const getPrevalues = (state) => state
export const getPrevalue = (state, id) => state.reduce((p, prevalue) => {
    if (prevalue.id === Number(id)) return prevalue
    return p
}, {})
export default prevalues