import Authpage from './Authpage'
import Backpage from './Backpage'
import Frontpage from './Frontpage'
import NoLogin from './NoLogin'
 
const LayoutSelection = ({ type, children }) => {

    let Layout
    switch(type){
        case 'backpage':
            Layout = Backpage
            break
        case 'frontpage':
            Layout = Frontpage
            break
        case 'NoLogin':
            Layout = NoLogin
            break
        default:
            Layout = Authpage
            break
    }

    return <Layout >
        {children}
    </Layout>
}

export default LayoutSelection