import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAgencies } from '../../reducers'
import styles from '../../styles'
import {
    Card, Descriptions, Skeleton, Avatar
} from 'antd'
import { WhatsAppOutlined, GlobalOutlined } from '@ant-design/icons'
import Layout from '../../layout'
import { APIpath } from '../../actions'



class AgenciesList extends React.Component {

    handleChange = (value) => {
        let filter = value !== 0
        this.setState({ zone: value, filter })
    }

    render() {
        const { agencies } = this.props
        return <Layout type={"frontpage"}>
            {agencies.length > 0 ?
                agencies.map(agency => {
                    return <Card
                        key={agency.id}
                        style={styles.generalCard}
                        actions={[
                            <Link to={`/agency/list/${agency.id}`}>
                                Más Información
                            </Link>,
                            <a href={`https://wa.me/+502${agency.cNroWhatsApp}`} target={"_blank"} rel="noreferrer">
                                <WhatsAppOutlined /> Escríbenos
                            </a>,
                            <a href={agency.cURLMaps} target={"_blank"} rel="noreferrer">
                                <GlobalOutlined /> Ver mapa
                            </a>
                        ]}
                    >
                        <Card.Meta
                            avatar={<Avatar size={64} src={`${APIpath}/agency/image/${agency.id}`} /> }
                            title={<h2> {agency.name} </h2>}
                        />
                        <Descriptions column={1}>
                            <Descriptions.Item label="Teléfono">{agency.phone}</Descriptions.Item>
                            <Descriptions.Item label="Dirección">{agency.address}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                })
                : <Skeleton />
            }
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    agencies: getAgencies(state)
})

const mapDispatchToProps = undefined

export default connect(mapStateToProps, mapDispatchToProps)(AgenciesList)