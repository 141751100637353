const invoice = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_INVOICES': return action.invoices
        case 'FETCH_INVOICE':
            state.push(action.invoice)
            return state
        default: return state
    }
}

export const getInvoices = (state) => state
export const getInvoice = (state, id) => state.reduce((p, invoice) => {
    if (invoice.NroDocumento === id) return invoice
    return p
}, {})


export default invoice