import React from 'react'
import { connect } from 'react-redux'
import { saveAdmin } from '../../actions'
import {
    Card, Form, Input, Button, Select
} from 'antd'
import Layout from '../../layout'
import styles from '../../styles'

const { Option } = Select

class CreateAdmin extends React.Component {

    handleGenerate = () => {
        let text = ''
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$#"
        for (var i = 0; i < 7; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length))
        this.setState({ password: text })
    }

    render() {

        const { saveAdmin } = this.props
        return (
            <Layout type={"backpage"}>
                <Card style={styles.generalCard}>
                    <Form
                        onFinish={saveAdmin}
                        layout={"vertical"}
                        name={"createAdmin"}
                    >
                        <Form.Item name={"email"} label={"Correo"} required>
                            <Input type={"email"} />
                        </Form.Item>
                        <Form.Item name={"name"} label={"Nombre"} required>
                            <Input />
                        </Form.Item>
                        <Form.Item name={"role_id"} label={"Rol"} required>
                            <Select>
                                <Option value={"1"}>Administrador</Option>
                                <Option value={"2"}>Gestion de usuarios</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item name="password" label="Contraseña" rules={[{ required: true, message: 'Coloca tu nueva contraseña para actualizar', },]} hasFeedback>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item name="confirm" label="Repetir contraseña" dependencies={['password']} hasFeedback
                            rules={[{ required: true, message: 'Repite tu contraseña para continuar', },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Debo coincidir con la nueva contraseña'));
                                },
                            }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                            <Button type="primary" htmlType="submit">Guardar</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Layout>
        )
    }
}


const mapStateToProps = undefined

const mapDispatchToProps = ({ saveAdmin })

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdmin)