const contract = (state = [], action) => {

    switch (action.type) {
        case 'FETCH_CONTRACTS': return action.contracts
        case 'FETCH_CONTRACT':
            state.push(action.contract)
            return state
        default: return state
    }
}

export const getContracts = (state) => state
export const getContract = (state, id) => state.reduce((p, contract) => {
    if (contract.contrato === Number(id)) return contract
    return p
}, {})


export default contract